import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Container } from "react-bootstrap"

import AnchorLink from "react-anchor-link-smooth-scroll"
import { FaArrowUp } from "react-icons/fa"

const Layout = ({ children }) => {
  const [scroll, setScroll] = useState(false)
  useEffect(() => {
    const listener = () => {
      setScroll(window.scrollY > 100)
    }
    window.addEventListener("scroll", listener)
    return () => window.removeEventListener("scroll", listener)
  }, [])
  return (
    <>
      {children}
      <footer id="footer">
        <Container className="d-md-flex py-4">
          <div className="mr-md-auto text-center text-md-left">
            <div className="copyright">
              © Copyright <strong>Alex'n'Friends</strong>. All rights reserved.
              </div>
            <div className="credits">
              Design inspired by <a href="https://bootstrapmade.com/" rel="noreferrer" target="_blank">BootstrapMade</a>
            </div>
          </div>
        </Container>
      </footer>
      <AnchorLink aria-label="Back to top" href="#body" className={`back-to-top ${scroll ? "fadeIn" : "fadeOut"}`}>
        <i><FaArrowUp /></i>
      </AnchorLink>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
